import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Right } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'

const FollowupPage: React.FC = () => {
  const { t } = useTranslation()
  const inProp = useContentInProp()

  const content = {
    pretitle: t('home.followup_super', 'fill copy key: home.followup_super'),
    title: t('home.followup_title', 'fill copy key: home.followup_title'),
    text: t('home.followup_body', 'fill copy key: home.followup_body'),
    links: [
      {
        link: t('home.followup_cta1', 'fill copy key: home.followup_cta1'),
        url: '/home/followup/meet',
        variant: 'all',
      },
      {
        link: t(
          'home.followup_cta_right',
          'fill copy key: home.followup_cta_right',
        ),
        url: '/our-dna',
        variant: 'mdOnly',
      },
    ],
  }

  return (
    <>
      <Right>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="right"
            delay={1.1}
            time={1}
            url="/our-dna"
            text="our dna"
          />
        )}
      </Right>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
                <Box col={0.6} />
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default FollowupPage
